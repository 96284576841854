import React, { PureComponent } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import classNames from "classnames";
import { withSnackbar } from "react-simple-snackbar";

import { snackbarOptions } from "../../config";

import "./add-user.scss";

const ADD_USER_MUTATION = gql`
  mutation ADD_USER_MUTATION($userId: ID!, $loggedInUserId: ID!) {
    addUser(userId: $userId) {
      id
      addedBy(where: { id: $loggedInUserId }) {
        id
      }
    }
  }
`;

class AddUser extends PureComponent {
  render() {
    const { userId, isAlreadyAdded, loggedInUserId, modifierClass } =
      this.props;
    const { openSnackbar } = this.props;
    return (
      <div
        className={classNames("add-user", {
          "add-user--already-added": isAlreadyAdded,
          [`add-user--${modifierClass}`]: modifierClass,
        })}
      >
        <Mutation
          mutation={ADD_USER_MUTATION}
          variables={{ userId, loggedInUserId }}
          optimisticResponse={{
            addUser: {
              __typename: "UserNotMe",
              id: userId,
              addedBy: isAlreadyAdded
                ? []
                : [
                    {
                      id: loggedInUserId,
                      __typename: "UserNotMe",
                    },
                  ],
            },
          }}
        >
          {(addUser, { loading }) => {
            return (
              <button
                title={isAlreadyAdded ? "Remove user" : "Add user"}
                className="add-user__button"
                disabled={loading}
                onClick={() => {
                  addUser();
                  openSnackbar(
                    `${
                      isAlreadyAdded ? "Removed from " : "Added to "
                    }contacts list`
                  );
                }}
              >
                {!isAlreadyAdded && (
                  <span className="add-user__cta-text--add">Add</span>
                )}
                {isAlreadyAdded && (
                  <>
                    <span className="add-user__cta-text--added">Added</span>
                    <span className="add-user__cta-text--remove">Remove</span>
                  </>
                )}
              </button>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default withSnackbar(AddUser, snackbarOptions);
