import React, { PureComponent } from "react";
import ReactMarkdown from "react-markdown";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Link from "next/link";
import className from "classnames";
import { Mutation } from "react-apollo";

import customRendererLink from "../../utils/custom-link-renderer";
import ProfilePic from "../profile-pic/profile-pic";
import Cta from "../cta/cta";
import Tag from "../tag/tag";
import ReportUserButton from "../report-user-button/report-user-button";
import MiniMenu from "../mini-menu/mini-menu";
import PremiumBadge from "../premium-badge/premium-badge";
import AddUser from "../add-user/add-user";

import { LOCAL_TOGGLE_FOUNDERS_GRID_ADDITIONAL_DETAILS_MUTATION } from "../../apollo-state/graphql/mutations";

import "./user-tile.scss";

class UserTile extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const {
      firstName,
      profilePic,
      hasSkills,
      location,
      lookingToJoinStartupAsFounder,
      hasIdeaForStartup,
      whyMakeGoodCofounder,
      elevatorPitchForStartup,
      signedIn,
      hasValidCoFounderSubscription,
      activityStatus,
      id,
      me,
      addedBy,
    } = this.props;
    const userCountry = location.split(",");
    return (
      <ReactCSSTransitionGroup
        transitionName="overlay"
        transitionAppear={true}
        component={React.Fragment}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <article
          className={className("user-tile", {
            "user-tile--premium": hasValidCoFounderSubscription,
          })}
        >
          <div className="user-tile__inner">
            {profilePic && (
              <ProfilePic
                href={"/profile/[id]"}
                hrefAs={`/profile/${id}`}
                profilePic={profilePic}
                activityStatus={activityStatus}
                activityStatusPositionBelow={true}
                firstName={firstName}
                width={64}
                height={64}
              />
            )}
            <div className="user-tile__details">
              <div className="user-tile__indent">
                {signedIn && (
                  <MiniMenu
                    modifierClass={"user-tile__tile-options"}
                    minWidth="155px"
                  >
                    <ReportUserButton
                      copy="Report as poor or inappropriate content"
                      userId={id}
                      reportedFrom="FOUNDERGRID"
                    />
                  </MiniMenu>
                )}
                <div className="user-tile__first-name">
                  <Link href="/profile/[id]" as={`/profile/${id}`}>
                    <a className="user-tile__first-name__link">{firstName}</a>
                  </Link>
                  {hasValidCoFounderSubscription && (
                    <PremiumBadge
                      copy="Pro user"
                      showUpgradeHoverMessage={
                        me && !me.hasValidCoFounderSubscription
                      }
                    />
                  )}
                  {me && (
                    <AddUser
                      userId={id}
                      isAlreadyAdded={addedBy.length > 0}
                      loggedInUserId={me.id}
                    />
                  )}
                </div>
                <div className="user-tile__tags">
                  {hasSkills.map((skill) => {
                    return <Tag key={skill.id} label={skill.label} />;
                  })}
                </div>
              </div>
              <div className="user-tile__location">
                {userCountry[userCountry.length - 1]}
              </div>
              {hasIdeaForStartup && (
                <div className="user-tile__has-idea">
                  Has an idea for a startup
                </div>
              )}
              {lookingToJoinStartupAsFounder && (
                <div className="user-tile__looking-to-join">
                  Looking to partner with someone else's idea
                </div>
              )}

              <div className="user-tile__why-make">
                {!hasIdeaForStartup && (
                  <>
                    <strong>About {firstName}:</strong>
                    <ReactMarkdown
                      source={
                        whyMakeGoodCofounder?.length < 100
                          ? whyMakeGoodCofounder.replace(/<br \/>/g, " ")
                          : `${whyMakeGoodCofounder
                              ?.replace(/<br \/>/g, " ")
                              .substring(0, 100)
                              .trim()}...`
                      }
                      escapeHtml={false}
                      renderers={customRendererLink}
                    />
                  </>
                )}
                {hasIdeaForStartup && elevatorPitchForStartup && (
                  <>
                    <strong>{firstName}'s idea:</strong>
                    <ReactMarkdown
                      source={
                        elevatorPitchForStartup.length < 100
                          ? elevatorPitchForStartup.replace(/<br \/>/g, " ")
                          : `${elevatorPitchForStartup
                              .replace(/<br \/>/g, " ")
                              .substring(0, 100)
                              .trim()}...`
                      }
                      escapeHtml={false}
                      renderers={customRendererLink}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="user-tile__toggle">
              <div className="user-tile__toggle__inner">
                <Mutation
                  mutation={
                    LOCAL_TOGGLE_FOUNDERS_GRID_ADDITIONAL_DETAILS_MUTATION
                  }
                  variables={{
                    foundersGridAdditionalDetailsId:
                      this.props.id === this.props.openTileId
                        ? ""
                        : this.props.id,
                  }}
                >
                  {(toggleFoundersGridAdditionalDetails) => (
                    <Cta
                      tag="button"
                      text="Quick view"
                      icon="plus"
                      onClick={toggleFoundersGridAdditionalDetails}
                    />
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </article>
      </ReactCSSTransitionGroup>
    );
  }
}

export default UserTile;
